import vue from "vue";
import App from "./App.vue";
import store from "./store/index.js";
import axios from "axios";

window.vueMedia = () => {
  vue.config.devtools = false;
  vue.config.productionTip = false;
  new vue({
    store,
    axios,
    render: (h) => h(App),
  }).$mount("#vue-media-app");
};
