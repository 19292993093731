var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("div", { staticClass: "form-wrap mapping-page mapping-padding" }, [
        _c(
          "div",
          { staticClass: "content-block main-form-type" },
          [
            _c("actions", {
              attrs: {
                "open-konva": _vm.openKonva,
                "invert-settings": _vm.invertSetting
              },
              on: {
                downloadPhoto: _vm.showEditPopUp,
                submitPolygon: _vm.submitPolygon,
                showGallery: _vm.showGallery,
                resetFilter: function($event) {
                  _vm.resetFilter = !_vm.resetFilter
                },
                invertSettings: function($event) {
                  _vm.invertSetting = !_vm.invertSetting
                }
              }
            }),
            _vm._v(" "),
            _c("filters-panel", {
              attrs: {
                "open-konva": _vm.openKonva,
                "reset-filter": _vm.resetFilter
              },
              on: { submitPolygon: _vm.submitPolygon }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("gallery", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.openKonva,
            expression: "!openKonva"
          }
        ],
        on: { openKonva: _vm.showKonva, showEditPopUp: _vm.showEditPopUp }
      }),
      _vm._v(" "),
      _vm.showPopup
        ? _c(
            "pop-up",
            {
              ref: "popUp",
              staticClass: "pop-up-vue",
              on: {
                cancelPopUp: function($event) {
                  _vm.showPopup = false
                }
              }
            },
            [
              _c("pop-up-gallery", {
                attrs: { "photo-pop-up": _vm.photoPoUp },
                on: {
                  destroyPopUp: function($event) {
                    _vm.showPopup = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.openKonva
        ? _c("konva2", {
            ref: "konva2",
            attrs: { "invert-setting": _vm.invertSetting },
            on: {
              editPolygon: _vm.selectFilterForPoly,
              mouseOverPoly: _vm.mouseOverPoly,
              resetFilters: _vm.resetFilters,
              submitPolygon: _vm.submitPolygon
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "info",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.openKonva,
              expression: "openKonva"
            }
          ]
        },
        [_vm._v(" " + _vm._s(_vm.stat) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }