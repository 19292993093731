var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "editorJson" } },
    [
      _c("ace", {
        attrs: { lang: "json", theme: "monokai", width: "100%", height: "780" },
        on: { onChange: _vm.changeAce, init: _vm.init },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "submit", value: "Сохранить" },
        on: { click: _vm.saveJson }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "submit", value: "Deploy DEV" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.deployDev($event)
          }
        }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "submit", value: "Deploy PROD" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.deployProd($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }