var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gallery" }, [
    _c(
      "div",
      { staticClass: "photos" },
      [
        _c(
          "draggable",
          {
            model: {
              value: _vm.filteringPhoto,
              callback: function($$v) {
                _vm.filteringPhoto = $$v
              },
              expression: "filteringPhoto"
            }
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "list", tag: "div" } },
              _vm._l(_vm.filteringPhoto, function(photo) {
                return _c(
                  "div",
                  { key: photo.id, staticClass: "photo list-item" },
                  [
                    _c("div", {
                      staticClass: "cross icon_close",
                      on: {
                        click: function($event) {
                          return _vm.onRemovePhoto(photo.id)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "edit-icon icon_edit",
                      on: {
                        click: function($event) {
                          return _vm.$emit("showEditPopUp", photo)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "mapping-items" }, [
                      _vm._v(
                        "Кол-во выделленых областей: " +
                          _vm._s(Object.values(photo.mapping).length)
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "img",
                      style: "background-image: url(" + photo.img + ");",
                      on: {
                        click: function($event) {
                          return _vm.$emit("openKonva", photo)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "description",
                        on: {
                          click: function($event) {
                            return _vm.$emit("showEditPopUp", photo)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              photo.description
                                ? photo.description
                                : "Нажмите чтоб изменить название"
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }