<template>
  <div class="mappigKonva" tabindex="0">
    <v-stage
      ref="stage"
      class="mapping-stage"
      :config="{
        width: 1920,
        height: 620,
      }"
      @click="clickStage"
      @mousemove="moveImage"
      @wheel="scaleAll"
      @mouseup="stopDragImage"
    >
      <v-layer ref="layerImg">
        <v-image
          :config="{
            x: 0,
            y: 0,
            image: loadImg,
            width: photo.width,
            height: photo.height,
          }"
        ></v-image>
      </v-layer>
      <v-layer ref="layer">
        <v-group
          v-for="(poly, i) in photo.mapping"
          ref="groupPoly"
          :key="i"
          :config="poly"
          @dragend="dragendGroup"
          @mouseenter="$emit('mouseOverPoly', poly.coordId)"
        >
          <v-line
            ref="line"
            :config="{
              points: poly.coords,
              fill: poly.fill,
              stroke: poly.stroke,
              strokeWidth: poly.strokeWidth,
              closed: true,
              coordId: poly.coordId,
              bezier: poly.bezier,
            }"
            @contextmenu="contextmenu"
          >
          </v-line>
          <v-circle
            v-for="(point, k) in coordsToPoints(poly.coords)"
            :key="k"
            :config="{
              x: point.x,
              y: point.y,
              radius: poly.radius ? poly.radius / deltaAllScale : 2,
              fill: 'transparent',
              stroke: 'red',
              strokeWidth: 1,
              draggable: draggableCircle,
              coordId: poly.coordId,
            }"
            @contextmenu="removePoint"
            @mouseover="mouseoverCircle"
            @mouseout="mouseoutCircle"
            @dragmove="dragmoveCircle"
          >
          </v-circle>
        </v-group>
      </v-layer>
    </v-stage>
    <context-menu
      v-show="showContextmenu"
      ref="contextMenu"
      @showDeletePopUp="showDeletePopUp"
      @edit="editPoly"
      @clone="copyPoly"
      @past="pastPoly"
      @click="showContextmenu = false"
      @submitPolygon="$emit('submitPolygon')"
    />
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import Vue from "vue";
import contextMenu from "./contextMenu.vue";
import VueSwal from "vue-swal";
import {find, map, remove} from "lodash";
import {coordsToPoints, pointsToCoords, coordsToBezier, validateSelectedFilters} from "../utils.js";

Vue.use(VueSwal);
import VueKonva from "vue-konva";
Vue.use(VueKonva);
const SPEED_SCALE = 1.25;

export default {
  components: {
    contextMenu,
  },
  props: {
    buttonSaveClick: {
      type: Boolean,
      default: false,
    },
    invertSetting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spacePress: false,
      indexEditPoly: -1,
      deltaAllScale: 1,
      eventClick: {},
      currentGroup: {},
      flagLoad: 0,
      //AIM: 15 ,// автоприцел 10 пикселей))
      showContextmenu: false,
      contextElemCoordId: "",
      radiusEditedCircle: 3,
      draggableCircle: false,
      newPos: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    ...mapState("mapping", ["photo", "configPoly", "configCircle", "copiedElement"]),
    ...mapState("gallery", ["filters"]),
    AIM() {
      return 20 / this.deltaAllScale;
    },
    loadImg() {
      const img = new Image(this.photo.width, this.photo.height);
      img.onload = () => {
        this.$refs.layerImg.getStage().draw();
      };
      img.src = this.photo.img;
      return img;
    },
  },
  methods: {
    ...mapMutations("gallery", ["removePolygonInPhotos"]),
    ...mapMutations("mapping", ["setCopiedElemet"]),
    pointsToCoords,
    coordsToPoints,
    validateSelectedFilters,
    keyDown(e) {
      this.spacePress = e.code === "Space";
    },
    keyUp(e) {
      if (e.code === "Space") {
        this.spacePress = false;
      }
    },
    dragendGroup(c) {
      if (c.config.radius !== undefined) {
        return false;
      }
      const index = this.polygons.findIndex((el) => el.customId === this.contextElem.config.customId);
      this.currentGroup = this.polygons[index];
      this.currentGroup.edited = false;
      this.currentGroup.moved = true;
      this.contextElem.getStage().getParent().draggable(false);
    },
    contextmenu(c, e) {
      this.showContextmenu = true;
      this.contextElemCoordId = c.config.coordId;
      this.$refs.contextMenu.$el.style.left = e.evt.pageX + "px";
      this.$refs.contextMenu.$el.style.top = e.evt.pageY + "px";
      e.evt.stopPropagation();
      e.evt.preventDefault();
    },
    findIndexByCoordId(coordId) {
      let index = null;
      for (let key in this.photo.mapping) {
        if (this.photo.mapping[key].coordId === coordId) {
          index = key;
        }
      }
      return index;
    },
    editPoly(e) {
      // если хоть 1 полигон редактируеться не начинать нове редактирование
      if (this.photo.mapping.edit) {
        this.$showNoty({msg: "Сначало завершите редактирование", type: "error"});
        this.showContextmenu = false;
        return false;
      }
      const index = this.findIndexByCoordId(this.contextElemCoordId);
      this.photo.mapping.edit = this.photo.mapping[index];
      //Vue.set(this.photo.mapping, 'edit', this.photo.mapping[index])
      //Vue.set(this.photo.mapping, 'edit', this.clone(this.photo.mapping[index]))

      this.photo.mapping.edit.radius = this.radiusEditedCircle;
      this.photo.mapping.edit.stroke = "springgreen";
      this.$emit("editPolygon", this.contextElemCoordId);
      delete this.photo.mapping[index];
      this.showContextmenu = false;
      e.stopPropagation();
      return true;
    },
    copyPoly() {
      const copiedElem = this.clone(this.photo.mapping[this.contextElemCoordId]);
      this.setCopiedElemet(copiedElem);
      this.showContextmenu = false;
    },
    pastPoly() {
      if (!this.copiedElement.coordId) {
        this.$showNoty({msg: "Не чего не скопированно", type: "error"});
        return false;
      }
      if (this.photo.mapping.edit) {
        this.$showNoty({msg: "Сначало завершите редактирование", type: "error"});
        this.showContextmenu = false;
        return false;
      }

      this.photo.mapping.edit = this.copiedElement;
      // чтоб не удалил копируемый елемент
      this.photo.mapping.edit.coordId = "";
      this.photo.mapping.edit.radius = this.radiusEditedCircle;
      this.$emit("editPolygon", this.contextElemCoordId);
      this.setCopiedElemet("");

      this.showContextmenu = false;
    },
    showDeletePopUp(e) {
      this.$swal({
        title: "Удаление выделенной области",
        text: "Вы действительно хотите удалить?",
        icon: "warning",
        buttons: ["Отмена", "Да"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deletePoly(this.contextElemCoordId);
          if (this.photo.mapping.edit && this.photo.mapping.edit.coordId === this.contextElemCoordId) {
            delete this.photo.mapping.edit;
          }
          delete this.photo.mapping[this.contextElemCoordId];

          this.showContextmenu = false;
        }
      });
      e.preventDefault();
      return false;
    },
    deletePoly(id) {
      if (id) {
        return Vue.http
          .get(Routing.generate("navigator_mapping_remove"), {
            params: {
              id: id,
            },
          })
          .then((response) => {
            this.$showNoty({msg: "Успешно удалена", type: "success"});
          });
      } else {
        delete this.photo.mapping.edit;
        return new Promise((res, rej) => {
          res("foo");
        });
      }
    },
    changePointSize(e) {
      this.defaultConfigCircle.radius = e.target.value;
      this.currentGroup.circle.forEach((obj) => {
        obj.radius = e.target.value;
      });
    },
    moveImage(c, event) {
      const e = event.evt;
      if (this.spacePress || e.ctrlKey) {
        const stage = this.$refs.stage.getStage();
        stage.draggable(true);
      }
    },
    stopDragImage() {
      const stage = this.$refs.stage.getStage();
      stage.draggable(false);
    },
    scaleAll(c, e) {
      var scaleBy = SPEED_SCALE;
      const stage = this.$refs.stage.getStage();
      var oldScale = stage.scaleX();
      var pointer = stage.getPointerPosition();
      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };
      var newScale = "";
      if (this.invertSetting) {
        newScale = e.evt.deltaY * -1 > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      } else {
        newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      }

      stage.scale({x: newScale, y: newScale});
      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      this.newPos = newPos;
      stage.position(newPos);
      stage.batchDraw();

      this.deltaAllScale = newScale;
    },
    calculatedXY(xy, group) {
      const stage = this.$refs.stage.getStage();
      let x = (xy[0] - stage.x() - group.x * this.deltaAllScale) / (group.scaleX * this.deltaAllScale), ///
        y = (xy[1] - stage.y() - group.y * this.deltaAllScale) / (group.scaleY * this.deltaAllScale); ///
      return {x: x, y: y};
    },
    dragmoveCircle(c, e) {
      this.photo.mapping.edit.coords = this.photo.mapping.edit.coords
        .join(",")
        .replace(c.config.x + "," + c.config.y, e.target.x() + "," + e.target.y());
      this.photo.mapping.edit.coords = map(this.photo.mapping.edit.coords.split(","), (v) => parseFloat(v, 10));
    },
    mouseoutCircle(e, c) {},
    mouseoverCircle(e, c) {
      if (this.photo.mapping.edit && e.config.coordId === this.photo.mapping.edit.coordId) {
        this.draggableCircle = true;
      }
      document.body.style.cursor = "pointer";
    },
    removePoint(c, e) {
      if (e.evt.button === 2) {
        const XY = this.calculatedAIM({x: e.target.x(), y: e.target.y()}, true);
        this.photo.mapping.edit.coords.reduce((a, b, i) => {
          if (a === XY.x && b === XY.y) {
            this.photo.mapping.edit.coords.splice(i - 1, 2);
          }
          return b;
        });
        if (!this.photo.mapping.edit.coords.length) {
          if (this.photo.mapping.edit.coordId) {
            // не позволяю удалять объект сохраненый в базе
            // будут срабатывать предупредждения, укажите три точки или завершите редактирование
            return false;
          } else {
            delete this.photo.mapping.edit;
          }
        }
        e.evt.preventDefault();
      }
    },
    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    calculatedAIM(XY, edited = false) {
      // поиск соседних точек чтоб увеличить меткость попадания
      const arrPoints = [];
      const sourcePoints = [];
      for (let key in this.photo.mapping) {
        //не беру в расчет точки текущего полигона
        if (key === "edit" || edited) {
          continue;
        }
        const objPoly = this.photo.mapping[key];
        objPoly.coords = coordsToPoints(objPoly.coords);
        objPoly.coords.forEach((point) => {
          if (
            XY.x >= point.x - this.AIM &&
            XY.x <= point.x + this.AIM &&
            XY.y >= point.y - this.AIM &&
            XY.y <= point.y + this.AIM
          ) {
            const xy = point;

            arrPoints.push(Math.sqrt(Math.pow(XY.x - xy.x, 2) + Math.pow(XY.y - xy.y, 2)));
            sourcePoints.push(xy);
          }
        });
        objPoly.coords = pointsToCoords(objPoly.coords);
      }
      if (arrPoints.length >= 1) {
        const arrPointSource = this.clone(arrPoints);
        //нахожу индекс самого маленького елмента в массиве
        //soiurcePoints имеет одинаковую мерность массива с arrPoints то по нему нхожу реальные координаты точек
        const index = arrPointSource.indexOf(arrPoints.sort((a, b) => a - b)[0]);
        XY = sourcePoints[index];
      }
      return XY;
    },
    editGroup(e) {
      if (!this.photo.mapping.edit) {
        //create new
        const clonePoly = this.clone(this.configPoly);
        clonePoly.stroke = "springgreen";
        Vue.set(this.photo.mapping, "edit", clonePoly);
        //this.photo.mapping.edit = this.configPoly
        this.photo.mapping.edit.radius = this.radiusEditedCircle;
      }

      const XY = this.calculatedAIM(this.calculatedXY([e.evt.layerX, e.evt.layerY], this.photo.mapping.edit));
      this.photo.mapping.edit.coords.push(XY.x);
      this.photo.mapping.edit.coords.push(XY.y);
    },
    //клик по всей рабочей области
    clickStage(c, e) {
      if (e.evt.button === 2) {
        return false;
      } else {
        this.showContextmenu = false;
      }
      this.editGroup(e);
    },

    //завершение отрисовки фигуры
    submitPolygon() {
      if (!this.photo.mapping.edit) {
        this.$showNoty({msg: "Укажите минимум три точки", type: "error"});
        return false;
      }

      if (this.photo.mapping.edit.coords.length < 5) {
        this.$showNoty({msg: "Укажите минимум три точки", type: "error"});
        return false;
      }

      if (this.validateSelectedFilters(this.filters)) {
        this.$showNoty({msg: this.validateSelectedFilters(this.filters), type: "error"});
        return false;
      }
      let coords = "";
      this.photo.mapping.edit.systemCoords = this.photo.mapping.edit.coords.join(",");

      if (this.photo.mapping.edit.bezier) {
        coords = coordsToBezier(this.clone(this.photo.mapping.edit.coords));
      } else {
        coords = this.photo.mapping.edit.coords.join(",");
      }
      this.draggableCircle = false;
      this.photo.mapping.edit.stroke = "red";
      const params = {
        ...this.photo.mapping.edit,
        ...{
          coords: coords,
          bezier: this.photo.mapping.edit.bezier ? "bezier" : null,
        },
        ...this.filters,
        ...{photoId: this.photo.id},
      };
      if (this.photo.mapping.edit.coordId) {
        this.deletePoly(this.photo.mapping.edit.coordId).then(() => {
          this.savePoly(params);
        });
      } else {
        this.savePoly(params);
      }
    },

    savePoly(params) {
      // working post
      Vue.http.post(Routing.generate("navigator_mapping_save"), params, {emulateJSON: true}).then(
        (response) => {
          if (response) {
            this.$showNoty({msg: "Сохранено", type: "success"});
            const body = response.body;
            this.photo.mapping[body.id] = this.photo.mapping.edit;
            const mapping = this.photo.mapping[body.id];
            mapping.coordId = body.id;
            mapping.radius = 2;
            mapping[body.type] = body[body.type];
            mapping.houseNum = body.houseNum;
            mapping.section = body.section;
            mapping.sectionId = body.sectionId;
            mapping.sectionNum = body.sectionNum;
            mapping.floor = body.floor;
            mapping.floorId = body.floorId;
            mapping.floorNum = body.floorNum;
            mapping.realty = body.realty;
            delete this.photo.mapping.edit;

            this.$emit("resetFilters", body.id);
          }
        },
        (err) => {
          throw err;
        },
      );
    },
  },
  mounted() {
    this.loadImg.onload = () => {
      this.$refs.layerImg.getStage().draw();
    };
    window.addEventListener("keydown", this.keyDown);
    window.addEventListener("keyup", this.keyUp);
  },

  created() {
    let bezier = 0;
    this.$eventBus.$on("onBezierMode", bezier);
  },
  beforeDestroy() {
    if (this.photo.mapping.edit) {
      if (this.photo.mapping.edit.coordId) {
        this.photo.mapping[this.photo.mapping.edit.coordId] = this.photo.mapping.edit;
        this.photo.mapping[this.photo.mapping.edit.coordId].coords = this.photo.mapping[
          this.photo.mapping.edit.coordId
        ].systemCoords;
        this.photo.mapping[this.photo.mapping.edit.coordId].radius = 0;
      }
      delete this.photo.mapping.edit;
    }
    window.removeEventListener("keydown", this.keyDown);
    window.removeEventListener("keyup", this.keyUp);
  },
};
</script>

<style scoped lang="scss">
.control {
  padding-left: 20px;
  padding-bottom: 13px;
}

textarea {
  position: absolute;
  top: 0;
  right: 0;
}

.mapping-stage {
  border: 1px solid black;
  background-color: white;
  background-image: linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 74%, #bbb 75%, #bbb),
    linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 74%, #bbb 75%, #bbb);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
  overflow: hidden;
  //position: relative;
}

input[type="range"] {
  width: 300px;
  display: inline-block;
}
</style>
