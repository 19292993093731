var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pop-up-vue" }, [
    _c("form", { ref: "form", attrs: { action: "" } }, [
      _c("div", [
        _c("label", { attrs: { for: "discount_img_path" } }, [
          _vm._v("Загрузка фото для маппинга")
        ]),
        _vm._v(" "),
        _c("input", {
          ref: "file",
          attrs: { id: "discount_img_path", type: "file", name: "img_path[]" },
          on: { change: _vm.handleFileUpload }
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "loading-file-vue short",
          attrs: { name: "img_name", readonly: "", type: "text" },
          domProps: { value: _vm.file.name },
          on: { click: _vm.uploadPhoto }
        }),
        _vm._v(" "),
        _c("a", {
          staticClass: "icon_overview load-img load-file-x",
          attrs: { href: "#" },
          on: { click: _vm.uploadPhoto }
        })
      ]),
      _vm._v(" "),
      _c("div", [
        _c("label", { attrs: { for: "descMapping" } }, [
          _vm._v("Название фото")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name"
            }
          ],
          attrs: { id: "descMapping", type: "text", name: "descMapping[]" },
          domProps: { value: _vm.name },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.name = $event.target.value
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }