var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "col col-2 custom-attach-field custom-file-loader custom-file-loader-x first",
      class: { gallery: !_vm.openKonva }
    },
    [
      _c("div", { staticClass: "housephotos-actions disabled" }, [
        _vm.openKonva
          ? _c(
              "a",
              {
                staticClass: "action bezier",
                class: { active: _vm.bezierModeOn },
                attrs: { href: "#", title: "режим bezier" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.bezierMode($event)
                  }
                }
              },
              [_c("div")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("a", {
          staticClass: "action icon_download",
          attrs: { href: "#", title: "загрузить фото" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("downloadPhoto")
            }
          }
        }),
        _vm._v(" "),
        _vm.openKonva
          ? _c("a", {
              staticClass:
                "action icon_success2 house-reset-x create-mapping-x",
              attrs: { href: "#", title: "применить" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$emit("submitPolygon")
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("a", {
          staticClass: "action icon_refresh",
          attrs: { href: "#", title: "сбросить фильтры" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.$emit("resetFilter")
            }
          }
        }),
        _vm._v(" "),
        _vm.openKonva
          ? _c("a", {
              staticClass: "action icon_picture",
              attrs: { href: "#", title: "открыть галлерею" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$emit("showGallery")
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.openKonva
          ? _c("a", {
              staticClass: "action icon_reset",
              class: { rotate: _vm.invertSettings },
              attrs: { href: "#", title: "инвертировать настройки" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.$emit("invertSettings")
                }
              }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }