<template>
  <div class="wrap-media-v"
       @dragover.prevent=""
       @drop="uploadPhotoDrop"
  >
    <a class="action-gallery icon_download" href="#" title="загрузить фото" @click="showPopup = true"> </a>
    <!--    <select v-model="selectedDomain">-->
    <!--      <option v-for="domain in domainsList" :value="domain.id">{{ domain.value }}</option>-->
    <!--    </select>-->

    <select v-model="selectedType">
      <option v-for="type in fileTypes" :key="type" :value="type">{{ type }}</option>
    </select>
    <draggable v-model="fiteringPhotos" class="dashboard"

    >
      <transition-group name="list" tag="div">
        <div v-for="(photo, i) in fiteringPhotos" :key="photo.id" class="photo list-item">
          <div class="cross icon_close" @click="onRemovePhoto(photo.id)"></div>
          <!--          <div class="edit-icon icon_edit" @click="$emit('showEditPopUp', photo)"></div>-->
          <div
              :style="'background-image: url(' + bgImg(photo) + ');'"
              class="img"
              @click="indexLightBox = i"
          ></div>
          <div class="description" contenteditable="true" @click="$emit('showEditPopUp', photo)">
            {{ photo.url ? photo.url : "Нажмите чтоб изменить название" }}
            <template v-if="photo.preview"><br/> <br/>preview: <br/>{{ photo.preview ? photo.preview : "" }}</template>
          </div>
        </div>
      </transition-group>
    </draggable>
    <hr/>
    <CoolLightBox
        :items="photosLightBox"
        :index="indexLightBox2"
        :slideshow="false"
        :loop="false"
        @close="indexLightBox = null">
    </CoolLightBox>
    <pop-up
        v-if="showPopup"
        ref="popUp"
        class="pop-up-vue"
        @cancelPopUp="showPopup = false"
        @submitPopUp="submitForm"
        :loadingUpload="loadingUpload"
    >
      <div class="wrap-popup">
        <input ref="file" multiple type="file" @change="handleFileUpload"/>
        <!--        <div>-->
        <!--          <label for="doma">Список доменов</label>-->
        <!--          <select id="doma" v-model="selectedDomain">-->
        <!--            <option v-for="domain in domainsList" :value="domain.id">{{ domain.value }}</option>-->
        <!--          </select>-->
        <!--        </div>-->

        <div>
          <label for="types" class="required-field">Типы фото</label>
          <div class="flex">
            <select id="types" v-model="selectedType" required>
              <option v-for="type in fileTypes" :key="type" :value="type">{{ type }}</option>
            </select>
            <a class="d-block icon_overview load-img load-file-x" href="#" @click="uploadPhoto"> </a>
          </div>
        </div>
      </div>
    </pop-up>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import draggable from "vuedraggable";
import axios from "axios";
import popUp from "../components/ui/popUp";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {draggable, popUp, CoolLightBox},
  data() {
    return {
      // namePhoto: "",
      showPopup: false,
      selectedType: "",
      selectedTypeSite: "",
      types: [
        {
          id: "1",
          name: "compas",
          url: "123",
        },
      ],
      indexLightBox: null,
      indexLightBox2: null,
      selectedDomain: "",
      loadingUpload:false
    };
  },
  watch: {
    indexLightBox(el) {
      this.indexLightBox2 = null
      // dirty hack
      setTimeout(() => {
        this.indexLightBox2 = 0
      }, 200)
    },
    fileTypes(type){
      this.selectedType = Object.values(type)[0]
    }
  },
  computed: {
    ...mapState(["domainsList", "fileTypes", "photoList"]),
    photosLightBox() {
      return [Object.values(this.fiteringPhotos).map(photo => photo.url)[this.indexLightBox]]
    },
    fiteringPhotos() {
      if (this.selectedType) return this.photoList.filter((el) => el.type === this.selectedType).reverse();
      return this.photoList.reverse();
    },
  },

  mounted() {
    this.$store.dispatch("loadDomainList");
    this.$store.dispatch("getAllTypesFile");
    this.$store.dispatch("loadPhotoList");
  },
  destroyed() {
  },

  methods: {
    ...mapMutations(["setPhoto", "removePhoto"]),
    async onRemovePhoto(id) {
      const endpoint = Routing.generate(`navigator_settings_remove_photo_landing`);
      await axios.post(endpoint, {id: id});
      this.removePhoto(id);
    },
    uploadPhotoDrop(e){
      e.preventDefault()
      //1 mb
      const BYTES_IN_MB = 1048576
      const fileInstance = e.dataTransfer.files
      const arrayfileInstance = Object.values(fileInstance)
      if (arrayfileInstance.some(
          ({size, type})=>{
            if (size > 10 * BYTES_IN_MB) {
              this.$showNoty({msg: "Можно загружать файлы только до 10 мб", type: "error"});
              return true
            }
            if (!type.startsWith('image/')) {
              this.$showNoty({msg: "Можно загружать только изображения", type: "error"});
              return true
            }
          }
        )
      ) {
        return
      }

      this.submitForm(fileInstance)

    },
    bgImg(photo) {
      return photo.preview ? photo.preview : photo.url
    },
    uploadPhoto() {
      this.$refs.file.click();
    },

    handleFileUpload(files=false) {
      const formData = new FormData();
      let inputFile
      if (files) {
        inputFile = files
      }
      else {
         inputFile = this.$refs.file.files;
      }

      inputFile.length;

      for (let i = 0; i < inputFile.length; i++) {
        formData.append("uploadFiles[]", inputFile[i]);
      }

      formData.append("settingsId", $("#settings-id").html());
      formData.append("type", this.selectedType);
      // formData.append("name", this.namePhoto);
      //this.namePhoto = inputFile.files[0].name;

      return formData;
    },

    async submitForm(files=false) {
      if (!this.selectedType) {
        this.$showNoty({msg: "Не выбран тип", type: "error"});
        return;
      }
      this.loadingUpload = true
      const endpoint = Routing.generate(`navigator_settings_save_phoyo_landing`);
      const {data} = await axios.post(endpoint, this.handleFileUpload(files), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.loadingUpload = false
      data.forEach((el) => {
        this.setPhoto(el);
      });
      console.log(this.fiteringPhotos)
      this.showPopup = false;
    },
  },
};
</script>
<style lang="scss">

.d-block {
  display: block;
}

.action-gallery {
  border: 1px solid #0ca2c0;
  padding: 7px;
  padding-top: 6px;
  padding-bottom: 8px;
}

.dashboard {
  margin-top: 20px;
  min-height: 800px;
}

.wrap-media-v {
  margin: 20px;
}

select {
  width: 300px;
  height: 30px;
  margin-right: 8px;
}

hr {
  opacity: 0;
  width: 100%;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}

.photo {
  //cursor: pointer;
  float: left;
  border: 1px solid #d7d7d7;

  position: relative;

  .img {
    cursor: pointer;
    width: 300px;
    height: 300px;
    background-size: cover;
  }

  .cross {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    background: black;
    padding: 2px;
    cursor: pointer;
    line-height: 24px;
    font-size: 24px;
  }

  .edit-icon {
    position: absolute;
    right: 0;
    top: 28px;
    color: white;
    background: black;
    padding: 2px;
    cursor: pointer;
    line-height: 24px;
    font-size: 24px;
  }

  .mapping-items {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    padding: 2px 5px;
    background: black;
    line-height: 24px;
    font-family: OpenSansBold;
  }

  .description {
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    padding: 2px 5px;
    background: black;
    line-height: 10px;
    cursor: pointer;
    font-family: OpenSansLight;
    max-width: 300px;
    font-size: 8px;

  }
}

.wrap-popup {
  select {
    width: 100%;
  }

  .flex {
    display: flex;
  }

  input {
    width: 100%;
  }
}

.cool-lightbox {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  transition: all .3s ease
}

.cool-lightbox .cool-lightbox-zoom {
  position: absolute;
  bottom: 15px;
  left: 50%;
  display: flex;
  z-index: 99999;
  background-color: rgba(15, 15, 15, .8);
  border-radius: 8px;
  padding: 0 12px;
  align-items: center;
  transform: translateX(-50%)
}

.cool-lightbox .cool-lightbox-zoom input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 105px;
  background: 0 0
}

.cool-lightbox .cool-lightbox-zoom input[type=range]:focus {
  outline: 0
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: .2s;
  box-shadow: 0 0 0 #000;
  background: #e6e6e6;
  border-radius: 11px;
  border: 0 solid #000
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000;
  border: 1px solid #000;
  height: 12px;
  width: 12px;
  border-radius: 13px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4.5px
}

.cool-lightbox .cool-lightbox-zoom input[type=range]:focus::-webkit-slider-runnable-track {
  background: #e6e6e6
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: .2s;
  box-shadow: 0 0 0 #000;
  background: #e6e6e6;
  border-radius: 11px;
  border: 0 solid #000
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000;
  border: 1px solid #000;
  height: 12px;
  width: 12px;
  border-radius: 13px;
  background: #fff;
  cursor: pointer
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: .2s;
  background: 0 0;
  border-color: transparent;
  color: transparent
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-ms-fill-lower {
  background: #e6e6e6;
  border: 0 solid #000;
  border-radius: 22px;
  box-shadow: 0 0 0 #000
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-ms-fill-upper {
  background: #e6e6e6;
  border: 0 solid #000;
  border-radius: 22px;
  box-shadow: 0 0 0 #000
}

.cool-lightbox .cool-lightbox-zoom input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000;
  border: 1px solid #000;
  height: 12px;
  width: 12px;
  border-radius: 13px;
  background: #fff;
  cursor: pointer
}

.cool-lightbox .cool-lightbox-zoom input[type=range]:focus::-ms-fill-lower {
  background: #e6e6e6
}

.cool-lightbox .cool-lightbox-zoom input[type=range]:focus::-ms-fill-upper {
  background: #e6e6e6
}

.cool-lightbox .cool-lightbox-zoom .cool-lightbox-zoom__icon {
  height: 15px;
  width: 15px;
  color: #fff
}

.cool-lightbox .cool-lightbox-zoom .cool-lightbox-zoom__icon:first-of-type {
  margin-right: 10px
}

.cool-lightbox .cool-lightbox-zoom .cool-lightbox-zoom__icon:last-of-type {
  margin-left: 10px
}

.cool-lightbox .cool-lightbox-thumbs {
  position: absolute;
  height: 100vh;
  overflow-y: auto;
  width: 102px;
  right: -102px;
  top: 0;
  overflow-x: hidden;
  transition: none;
  background-color: #ddd;
  scrollbar-width: thin;
  scrollbar-color: #fa4242 rgba(175, 175, 175, .9)
}

@media (min-width: 767px) {
  .cool-lightbox .cool-lightbox-thumbs {
    transition: all .3s ease
  }
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar {
  width: 6px;
  height: 6px
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-button {
  width: 0;
  height: 0
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-thumb {
  background: #fa4242;
  border: 0 none #fff;
  border-radius: 50px
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-thumb:hover {
  background: #fff
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-thumb:active {
  background: #000
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-track {
  background: #e1e1e1;
  border: 0 none #fff;
  border-radius: 8px
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-track:hover {
  background: #666
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-track:active {
  background: #333
}

.cool-lightbox .cool-lightbox-thumbs::-webkit-scrollbar-corner {
  background: 0 0
}

@media (min-width: 767px) {
  .cool-lightbox .cool-lightbox-thumbs {
    width: 212px;
    right: -212px
  }
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list {
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
  padding-right: 0
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb {
  background-color: #000;
  width: 100%;
  margin-right: 2px;
  margin-bottom: 2px;
  display: block;
  height: 75px;
  position: relative
}

@media (min-width: 767px) {
  .cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb {
    width: calc(100% / 2 - 2px)
  }
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  content: '';
  z-index: 150;
  transition: all .3s ease;
  position: absolute;
  visibility: hidden;
  border: 3px solid #fa4242
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb.is-video .cool-lightbox__thumb__icon {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  transform: translate(-50%, -50%)
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb.is-video .cool-lightbox__thumb__icon path {
  fill: #fff
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb.is-video:after {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  position: absolute;
  background: rgba(0, 0, 0, .6)
}

.cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb.active:before, .cool-lightbox .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb:hover:before {
  opacity: 1;
  visibility: visible
}

.cool-lightbox .cool-lightbox__inner {
  padding: 60px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: none
}

@media (min-width: 767px) {
  .cool-lightbox .cool-lightbox__inner {
    transition: all .3s ease
  }
}

.cool-lightbox .cool-lightbox__progressbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  z-index: 500;
  transform-origin: 0;
  transform: scaleX(0);
  transition: transform 3s linear;
  display: block
}

.cool-lightbox.cool-lightbox--is-swipping {
  cursor: -webkit-grabbing;
  cursor: grabbing
}

.cool-lightbox.cool-lightbox--is-swipping iframe {
  pointer-events: none
}

.cool-lightbox.cool-lightbox--is-swipping .cool-lightbox__slide {
  transition: none
}

.cool-lightbox.cool-lightbox--is-swipping .cool-lightbox__slide.cool-lightbox__slide--hide {
  display: flex;
  z-index: 50
}

.cool-lightbox.cool-lightbox--zoom-disabled .cool-lightbox__slide .cool-lightbox__slide__img {
  transform: translate3d(-50%, -50%, 0)
}

.cool-lightbox.cool-lightbox--can-zoom .cool-lightbox__slide img {
  cursor: zoom-in
}

.cool-lightbox.cool-lightbox--is-zooming .cool-lightbox__slide img {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab
}

.cool-lightbox.cool-lightbox--is-zooming .cool-lightbox-caption {
  opacity: 0
}

.cool-lightbox.cool-lightbox--thumbs-right.cool-lightbox--show-thumbs .cool-lightbox__inner {
  right: 102px
}

@media (min-width: 767px) {
  .cool-lightbox.cool-lightbox--thumbs-right.cool-lightbox--show-thumbs .cool-lightbox__inner {
    right: 212px
  }
}

.cool-lightbox.cool-lightbox--thumbs-right.cool-lightbox--show-thumbs .cool-lightbox-thumbs {
  right: 0
}

.cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs {
  width: 100%;
  left: 0;
  right: 0;
  top: auto;
  height: 70px;
  bottom: -70px;
  overflow: auto
}

@media (min-width: 767px) {
  .cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs {
    bottom: -79px;
    height: 79px
  }
}

.cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs .cool-lightbox-thumbs__list {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center
}

.cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb {
  width: 100px;
  flex-shrink: 0;
  margin-bottom: 0;
  height: 65px
}

@media (min-width: 767px) {
  .cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs .cool-lightbox-thumbs__list .cool-lightbox__thumb {
    height: 75px
  }
}

.cool-lightbox.cool-lightbox--thumbs-bottom.cool-lightbox--show-thumbs .cool-lightbox__inner {
  bottom: 70px
}

@media (min-width: 767px) {
  .cool-lightbox.cool-lightbox--thumbs-bottom.cool-lightbox--show-thumbs .cool-lightbox__inner {
    bottom: 79px
  }
}

.cool-lightbox.cool-lightbox--thumbs-bottom.cool-lightbox--show-thumbs .cool-lightbox-thumbs {
  bottom: 0
}

.cool-lightbox * {
  box-sizing: border-box;
  padding: 0;
  margin: 0
}

.cool-lightbox button {
  background: 0 0;
  border: none;
  cursor: pointer;
  outline: 0
}

.cool-lightbox svg path, .cool-lightbox svg rect {
  fill: currentColor
}

.cool-lightbox .cool-lightbox-button {
  padding: 21px 16px 21px 4px;
  height: 100px;
  opacity: 1;
  z-index: 800;
  color: #ccc;
  transition: all .3s ease;
  position: absolute;
  top: calc(50% - 50px);
  width: 54px;
  transition: all .3s ease;
  visibility: visible
}

@media (min-width: 767px) {
  .cool-lightbox .cool-lightbox-button {
    width: 70px;
    padding: 31px 26px 31px 6px
  }
}

.cool-lightbox .cool-lightbox-button.hidden {
  opacity: 0;
  visibility: hidden
}

.cool-lightbox .cool-lightbox-button:hover {
  color: #fff
}

.cool-lightbox .cool-lightbox-button > .cool-lightbox-button__icon {
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(30, 30, 30, .6)
}

.cool-lightbox .cool-lightbox-button > .cool-lightbox-button__icon > svg {
  width: 100%;
  height: 100%
}

.cool-lightbox .cool-lightbox-button.cool-lightbox-button--prev {
  left: 0
}

.cool-lightbox .cool-lightbox-button.cool-lightbox-button--next {
  right: 0;
  padding: 21px 4px 21px 16px
}

@media (min-width: 767px) {
  .cool-lightbox .cool-lightbox-button.cool-lightbox-button--next {
    padding: 31px 6px 31px 26px
  }
}

.cool-lightbox .cool-lightbox-pdf {
  max-width: 100%
}

.cool-lightbox .cool-lightbox__iframe {
  width: 100%;
  display: flex;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1)
}

.cool-lightbox .cool-lightbox__iframe iframe {
  width: 100%;
  height: 100%
}

@media (min-width: 767px) {
  .cool-lightbox .cool-lightbox__iframe iframe {
    max-width: 80vw;
    max-height: 80vh
  }
}

.cool-lightbox .cool-lightbox__wrapper {
  width: 100%;
  height: 100%;
  position: relative
}

.cool-lightbox .cool-lightbox__wrapper.cool-lightbox__wrapper--swipe {
  display: flex;
  align-items: center
}

.cool-lightbox .cool-lightbox__wrapper.cool-lightbox__wrapper--swipe .cool-lightbox__slide {
  flex-shrink: 0;
  display: flex;
  position: relative;
  height: 100%;
  opacity: .4;
  transition: opacity .3s linear
}

.cool-lightbox .cool-lightbox__wrapper.cool-lightbox__wrapper--swipe .cool-lightbox__slide.cool-lightbox__slide--current {
  opacity: 1
}

.cool-lightbox .cool-lightbox__slide {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: none;
  position: absolute;
  margin-right: 30px;
  transition: transform .3s ease
}

.cool-lightbox .cool-lightbox__slide:last-of-type {
  margin-right: 0
}

.cool-lightbox .cool-lightbox__slide.cool-lightbox__slide--current {
  display: flex
}

.cool-lightbox .cool-lightbox__slide .cool-lightbox__slide__img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  transition: all .3s ease;
  display: flex
}

.cool-lightbox .cool-lightbox__slide img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  z-index: 9999;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, .45)
}

.cool-lightbox .cool-lightbox__slide picture {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  margin: auto;
  z-index: 9999
}

.cool-lightbox-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  display: flex;
  transition: all .3s ease;
  visibility: visible
}

.cool-lightbox-toolbar.hidden {
  opacity: 0;
  visibility: hidden
}

.cool-lightbox-toolbar .cool-lightbox-toolbar__btn {
  background: rgba(30, 30, 30, .6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin: 0;
  padding: 9px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 40px;
  height: 40px;
  color: #ccc
}

@media (min-width: 767px) {
  .cool-lightbox-toolbar .cool-lightbox-toolbar__btn {
    width: 44px;
    height: 44px;
    padding: 10px
  }
}

.cool-lightbox-toolbar .cool-lightbox-toolbar__btn > svg {
  width: 100%;
  height: 100%
}

.cool-lightbox-toolbar .cool-lightbox-toolbar__btn:hover {
  color: #fff
}

.cool-lightbox-caption {
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  opacity: 1;
  line-height: 1.5;
  padding: 18px 28px 16px 24px;
  right: 0;
  text-align: center;
  direction: ltr;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  z-index: 99997;
  background: linear-gradient(0deg, rgba(0, 0, 0, .75) 0, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, .15) 65%, rgba(0, 0, 0, .075) 75.5%, rgba(0, 0, 0, .037) 82.85%, rgba(0, 0, 0, .019) 88%, transparent)
}

@media (min-width: 767px) {
  .cool-lightbox-caption {
    padding: 22px 30px 23px 30px
  }
}

.cool-lightbox-caption a {
  color: #eee;
  text-decoration: underline
}

.cool-lightbox-caption h6 {
  font-size: 14px;
  margin: 0 0 6px 0;
  line-height: 130%
}

@media (min-width: 767px) {
  .cool-lightbox-caption h6 {
    font-size: 16px;
    margin: 0 0 6px 0
  }
}

.cool-lightbox-caption p {
  font-size: 13px;
  line-height: 130%;
  color: #ccc
}

@media (min-width: 767px) {
  .cool-lightbox-caption p {
    font-size: 15px
  }
}

.cool-lightbox-caption p a {
  color: #ccc
}

.cool-lightbox-caption p a:hover {
  color: #eee
}

.cool-lightbox-modal-enter-active, .cool-lightbox-modal-leave-active {
  transition: opacity .35s
}

.cool-lightbox-modal-enter, .cool-lightbox-modal-leave-to {
  opacity: 0
}

.cool-lightbox-slide-change-enter-active, .cool-lightbox-slide-change-leave-active {
  transition: opacity .27s
}

.cool-lightbox-slide-change-enter, .cool-lightbox-slide-change-leave-to {
  opacity: 0
}

.cool-lightbox-loading-wrapper {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%)
}

.cool-lightbox-loading-wrapper .cool-lightbox-loading {
  animation: cool-lightbox-rotate 1s linear infinite;
  background: 0 0;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  opacity: .7;
  padding: 0;
  width: 50px;
  z-index: 500
}

@keyframes cool-lightbox-rotate {
  100% {
    transform: rotate(360deg)
  }
}
</style>
