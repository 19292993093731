<template>
  <div ref="popUp" class="popup-mapping" @keyup.enter="$eventBus.$emit('submitPopUpGallery')">
    <div class="content-block main-form-type">
      <div class="custom-attach-field custom-file-loader">
        <div class="col first col-7">
          <slot></slot>
        </div>
      </div>
      <div class="col col-2">
        <div>
          <input
            type="submit"
            value="Ok"
            :disabled="loadingUpload"
            @click="
              $eventBus.$emit('submitPopUpGallery');
              $emit('submitPopUp');
            "
          />

        </div>
        <div>
          <input type="submit" class="cancel" value="Отмена" @click="$emit('cancelPopUp')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {props} from "vue2-ace-editor";

export default {
  props:['loadingUpload'],
  mounted() {
    this.fadedVue = document.createElement("div");
    this.fadedVue.classList.add("faded-vue");
    const body = document.getElementsByTagName("body")[0];
    body.append(this.fadedVue);
    this.fadedVue.addEventListener("click", this.emitDestroyPopUp);
    const popUp = this.$refs.popUp;
    popUp.style.top = document.documentElement.clientHeight / 2 - popUp.clientHeight / 2 + "px";
  },
  beforeDestroy() {
    this.fadedVue.removeEventListener("click", this.emitDestroyPopUp);
    this.fadedVue.remove();
  },
  destroyed() {
    this.fadedVue.remove();
  },
  methods: {
    emitDestroyPopUp(e) {
      this.$emit("destroyPopUp");
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-mapping {
  position: fixed;
  margin-left: calc(50% - 461px / 2);
  width: 461px;
  z-index: 9999999999;
  background: white;
  //top: calc(50% - 286px / 2);
  input[type="submit"] {
    float: left;
    margin-top: 10px;
    &.cancel {
      margin-left: 20px;
    }
  }
}
</style>
