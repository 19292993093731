var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "popUp",
      staticClass: "popup-mapping",
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.$eventBus.$emit("submitPopUpGallery")
        }
      }
    },
    [
      _c("div", { staticClass: "content-block main-form-type" }, [
        _c("div", { staticClass: "custom-attach-field custom-file-loader" }, [
          _c("div", { staticClass: "col first col-7" }, [_vm._t("default")], 2)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col col-2" }, [
          _c("div", [
            _c("input", {
              attrs: {
                type: "submit",
                value: "Ok",
                disabled: _vm.loadingUpload
              },
              on: {
                click: function($event) {
                  _vm.$eventBus.$emit("submitPopUpGallery")
                  _vm.$emit("submitPopUp")
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", [
            _c("input", {
              staticClass: "cancel",
              attrs: { type: "submit", value: "Отмена" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancelPopUp")
                }
              }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }