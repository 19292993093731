var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrap-media-v",
      on: {
        dragover: function($event) {
          $event.preventDefault()
        },
        drop: _vm.uploadPhotoDrop
      }
    },
    [
      _c("a", {
        staticClass: "action-gallery icon_download",
        attrs: { href: "#", title: "загрузить фото" },
        on: {
          click: function($event) {
            _vm.showPopup = true
          }
        }
      }),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedType,
              expression: "selectedType"
            }
          ],
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedType = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.fileTypes, function(type) {
          return _c("option", { key: type, domProps: { value: type } }, [
            _vm._v(_vm._s(type))
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "dashboard",
          model: {
            value: _vm.fiteringPhotos,
            callback: function($$v) {
              _vm.fiteringPhotos = $$v
            },
            expression: "fiteringPhotos"
          }
        },
        [
          _c(
            "transition-group",
            { attrs: { name: "list", tag: "div" } },
            _vm._l(_vm.fiteringPhotos, function(photo, i) {
              return _c(
                "div",
                { key: photo.id, staticClass: "photo list-item" },
                [
                  _c("div", {
                    staticClass: "cross icon_close",
                    on: {
                      click: function($event) {
                        return _vm.onRemovePhoto(photo.id)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "img",
                    style: "background-image: url(" + _vm.bgImg(photo) + ");",
                    on: {
                      click: function($event) {
                        _vm.indexLightBox = i
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "description",
                      attrs: { contenteditable: "true" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("showEditPopUp", photo)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            photo.url
                              ? photo.url
                              : "Нажмите чтоб изменить название"
                          ) +
                          "\n          "
                      ),
                      photo.preview
                        ? [
                            _c("br"),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v("preview: "),
                            _c("br"),
                            _vm._v(_vm._s(photo.preview ? photo.preview : ""))
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("CoolLightBox", {
        attrs: {
          items: _vm.photosLightBox,
          index: _vm.indexLightBox2,
          slideshow: false,
          loop: false
        },
        on: {
          close: function($event) {
            _vm.indexLightBox = null
          }
        }
      }),
      _vm._v(" "),
      _vm.showPopup
        ? _c(
            "pop-up",
            {
              ref: "popUp",
              staticClass: "pop-up-vue",
              attrs: { loadingUpload: _vm.loadingUpload },
              on: {
                cancelPopUp: function($event) {
                  _vm.showPopup = false
                },
                submitPopUp: _vm.submitForm
              }
            },
            [
              _c("div", { staticClass: "wrap-popup" }, [
                _c("input", {
                  ref: "file",
                  attrs: { multiple: "", type: "file" },
                  on: { change: _vm.handleFileUpload }
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "label",
                    { staticClass: "required-field", attrs: { for: "types" } },
                    [_vm._v("Типы фото")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedType,
                            expression: "selectedType"
                          }
                        ],
                        attrs: { id: "types", required: "" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.fileTypes, function(type) {
                        return _c(
                          "option",
                          { key: type, domProps: { value: type } },
                          [_vm._v(_vm._s(type))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("a", {
                      staticClass: "d-block icon_overview load-img load-file-x",
                      attrs: { href: "#" },
                      on: { click: _vm.uploadPhoto }
                    })
                  ])
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }