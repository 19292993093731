import {find} from "lodash";

export default {
  namespaced: true,
  state: {
    notifys: [],
    permissionNotify: false,
  },
  mutations: {
    removeNotifysById(state, id) {
      if (id) {
        const index = state.notifys.findIndex((notify) => notify.id == id);
        state.notifys.splice(index, 1);

        let storedNotify = localStorage.getItem("notifyList") ? JSON.parse(localStorage.getItem("notifyList")) : [];
        storedNotify.splice(index, 1);

        localStorage.setItem("notifyList", JSON.stringify(storedNotify));
      }
    },
    pushNotify(state, item) {
      if (!find(state.notifys, {id: item.id})) {
        state.notifys.push(item);
      }

      let storedNotify = localStorage.getItem("notifyList") ? JSON.parse(localStorage.getItem("notifyList")) : [];

      if (!find(storedNotify, {id: item.id})) {
        storedNotify.push(item);
        localStorage.setItem("notifyList", JSON.stringify(storedNotify));
      }
    },
    pushPermission(state, item) {
      state.permissionNotify = item;
    },
  },
  getters: {
    getNotifiys(state) {
      return state.notifys;
    },
    getNotifiyById: (state) => (id) => {
      //  return state.notifys;
    },
  },
  actions: {
    permissionAccess({state, commit}, item) {
      commit("pushPermission", item);
    },
    pushNotify({state, commit}, item) {
      commit("pushNotify", item);
      // window.Noty.closeAll();
      _.each(state.notifys, (el) => {
        if (!el) {
          return;
        }

        let alreadySent = localStorage.getItem("alreadySent") ? JSON.parse(localStorage.getItem("alreadySent")) : [];

        if ($.inArray(el.id, alreadySent) !== -1) {
          return false;
        }

        let theme = "info",
          message = el.description ? el.description : el.name;

        if (el.type === "booking" || el.eventType === "event_deal_book_term") {
          theme = "bookedCustom";
        }

        new window.Noty({
          type: theme,
          layout: "bottomRightCustom",
          theme: "crm",
          text: `<div class="icon_info crm_note"></div><div class="crm_note_txt">${message}</div>`,
          closeWith: ["click", "button"],
          callbacks: {
            onClick() {
              //VueStore.dispatch('crmNotify/removeNotify', el.id);
              commit("removeNotifysById", el.id);

              $.post(Routing.generate("crm_notify_remove"), {id: el.id});

              if (el.type) {
                switch (el.type) {
                  case "booking":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "discount":
                    location.href = Routing.generate("crm_admin_discount_edit", {id: el.elemId});
                    break;
                  case "dealQueue":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "paymentRent":
                    location.href = Routing.generate("crm_admin_rent_edit", {rent: el.elemId});
                    break;
                  default:
                    if (el.elemRefRecordId) {
                      $(window).trigger("open.lead.popup", [el.elemRefRecordId]);
                    } else {
                      $(window).trigger("calendar-event-edit", [el.elemId]);
                    }
                    break;
                }
              }

              if (el.eventType) {
                makePreloader();
                switch (el.eventType) {
                  case "event_deal_book_term":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "event_deal_payment_term":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "event_deal_transaction":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "event_deal_registration_term":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "event_deal_created":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "event_deal_updated":
                    location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                    break;
                  case "event_calendar_term":
                    destroyPreloader();
                    $(window).trigger("calendar-event-edit", [el.elemId]);
                    break;
                  case "event_calendar_created":
                    destroyPreloader();
                    $(window).trigger("calendar-event-edit", [el.elemId]);
                    break;
                  case "event_sub_task_create":
                    destroyPreloader();
                    $(window).trigger("calendar-event-edit", [el.elemId]);
                    break;
                  case "event_sub_task_term":
                    destroyPreloader();
                    $(window).trigger("calendar-event-edit", [el.elemId]);
                    break;
                  case "event_sub_task_close":
                    destroyPreloader();
                    $(window).trigger("calendar-event-edit", [el.elemId]);
                    break;
                  case "event_lead_created":
                    destroyPreloader();
                    $(window).trigger("open.lead.popup", [el.elemId]);
                    break;
                  case "event_lead_call":
                    destroyPreloader();
                    $(window).trigger("open.lead.popup", [el.elemId]);
                    break;
                  case "event_lead_manager_updated":
                    destroyPreloader();
                    $(window).trigger("open.lead.popup", [el.elemId]);
                    break;
                  case "event_lead_status_updated":
                    destroyPreloader();
                    $(window).trigger("open.lead.popup", [el.elemId]);
                    break;
                  case "event_lead_loyalty_updated":
                    destroyPreloader();
                    $(window).trigger("open.lead.popup", [el.elemId]);
                    break;
                  case "event_feed_generated":
                    location.href = Routing.generate("crm_feed_form_service", {
                      service: el.options.service,
                      object_type: el.options.objectType,
                    });
                    break;
                  case "event_feed_active_updated":
                    location.href = Routing.generate("crm_feed_form_service", {
                      service: el.options.service,
                      object_type: el.options.objectType,
                    });
                    break;
                  case "event_rent_created":
                    location.href = Routing.generate("crm_admin_rent_edit", {rent: el.elemId});
                    break;
                  case "event_rent_payment_term":
                    location.href = Routing.generate("crm_admin_rent_edit", {rent: el.elemId});
                    break;
                  case "event_rent_transaction":
                    location.href = Routing.generate("crm_admin_rent_edit", {rent: el.elemId});
                    break;
                  case "event_rent_ending":
                    location.href = Routing.generate("crm_admin_rent_edit", {rent: el.elemId});
                    break;
                  case "event_realty_price_updated":
                    location.href = Routing.generate("crm_admin_realty_edit", {id: el.elemId});
                    break;
                  case "event_realty_status_updated":
                    location.href = Routing.generate("crm_admin_realty_edit", {id: el.elemId});
                    break;
                  case "event_realty_square_updated":
                    location.href = Routing.generate("crm_admin_realty_edit", {id: el.elemId});
                    break;
                  case "event_discount_created":
                    location.href = Routing.generate("crm_admin_discount_edit", {id: el.elemId});
                    break;
                  case "event_discount_activated":
                    location.href = Routing.generate("crm_admin_discount_edit", {id: el.elemId});
                    break;
                  case "event_discount_ending":
                    location.href = Routing.generate("crm_admin_discount_edit", {id: el.elemId});
                    break;
                  case "event_important":
                    if (!el.options.customType) {
                      el.options.customType = null;
                    }

                    switch (el.options.customType) {
                      case "deal":
                        location.href = Routing.generate("crm_admin_docs_deals_edit", {id: el.elemId});
                        break;
                      default:
                        destroyPreloader();
                        break;
                    }
                    break;
                  default:
                    destroyPreloader();
                    break;
                }
              }
            },
            onClose() {
              commit("removeNotifysById", el.id);
              //VueStore.dispatch('crmNotify/removeNotify', el.id);
              $.post(Routing.generate("crm_notify_remove"), {id: el.id});
            },
          },
          progressBar: false,
        }).show();

        alreadySent.push(el.id);

        localStorage.setItem("alreadySent", JSON.stringify(alreadySent));
        message = message.replace("</div>", "</div>\r\n");
        message = $(message).text() ? $(message).text() : message;

        var notification = new Notification("xn--80aa5afjdkos.xn--d1aifhj1a.xn--p1ai", {body: message, dir: "auto"});
      });
    },
    removeNotify({commit}, item) {
      commit("removeNotifysById", item);
    },
  },
};
