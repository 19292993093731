import {map} from "lodash";
import {clone, coordsToPoints} from "../utils.js";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    photo: {},
    configCircle: {
      radius: 3,
      fill: "transparent",
      stroke: "red",
      strokeWidth: 1,
      draggable: true,
      num: 0,
    },
    configPoly: {
      fill: "rgba(0,0,0,.5)",
      stroke: "red",
      strokeWidth: 1,
      coords: [],
      draggable: false,
      bezier: false,
      radius: 0,
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    },
    copiedElement: "",
    loading: false,
  },
  mutations: {
    setBezierMode(state) {
      state.configPoly.bezier = !state.configPoly.bezier;
      if (state.photo.mapping) {
        if (state.photo.mapping.edit) {
          state.photo.mapping.edit.bezier = state.configPoly.bezier;
        }
      }
    },
    setCopiedElemet(state, item) {
      console.log(item);
      state.copiedElement = item;
    },

    setLineSettings(state, items) {
      state.defaultConfigPoly.bezier = items.item;
    },
    setSelectedPhoto(state, selectedPhoto) {
      state.photo = selectedPhoto;
    },
  },
  actions: {
    konvaInit({state, commit}) {
      if (Array.isArray(state.photo.mapping)) {
        state.photo.mapping = {};
      }

      for (let key in state.photo.mapping) {
        if (!Array.isArray(state.photo.mapping[key].coords)) {
          //преобразую строку в массив чисел
          state.photo.mapping[key].coords = map(state.photo.mapping[key].coords.split(","), (v) => parseFloat(v, 10));
          for (let k in state.configPoly) {
            if (undefined === state.photo.mapping[key][k]) {
              state.photo.mapping[key][k] = state.configPoly[k];
            }
          }
        }
        // warning konva
        //applyNodeProps.js:12 VueKonva: You are using "id" attribute for Konva node.
        // In some very rare cases it may produce bugs.
        // Currently we recommend not to use it and use "name" attribute instead.
        state.photo.mapping[key].coordId = state.photo.mapping[key].coordId
          ? state.photo.mapping[key].coordId
          : state.photo.mapping[key].id;

        delete state.photo.mapping[key].id;
        //state.photo.mapping[key].saved = true;
        //mapping.points = coordsToPoints(mapping.coords);
      }
      commit("set", {type: "photo", items: state.photo, module: "mapping"}, {root: true});
    },
  },
};
