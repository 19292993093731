var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "contextMenu", staticClass: "context-menu" }, [
    _c("ul", [
      _c(
        "li",
        {
          ref: "clone",
          on: {
            click: function($event) {
              return _vm.$emit("submitPolygon")
            }
          }
        },
        [_vm._v("Сохранить")]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          ref: "remove",
          on: {
            click: function($event) {
              return _vm.$emit("showDeletePopUp", $event)
            }
          }
        },
        [_vm._v("Удалить")]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          ref: "edit",
          on: {
            click: function($event) {
              return _vm.$emit("edit", $event)
            }
          }
        },
        [_vm._v("Редактировать")]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          ref: "clone",
          on: {
            click: function($event) {
              return _vm.$emit("clone", $event)
            }
          }
        },
        [_vm._v("Клонировать")]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          ref: "clone",
          class: { opacity: _vm.disabledPast },
          on: {
            click: function($event) {
              return _vm.$emit("past", $event)
            }
          }
        },
        [_vm._v("Вставить")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }