const noty = {
  install(vue) {
    vue.prototype.$showNoty = function ({type, msg, timeout = 3000}) {
      new Noty({
        type: type,
        layout: "bottomRightCustom",
        theme: "crm",
        timeout: timeout,
        text: `
          <div class="icon_${type} crm_note"></div>
          <div class="crm_note_txt">${msg}</div>`,
        closeWith: ["click", "button"],
        progressBar: false,
      }).show();
    };
  },
};
export default noty;
