<template>
  <div id="app">
    <page></page>
  </div>
</template>
<script>
import page from "./components/page.vue";

export default {
  components: {
    page,
  },
  created() {},
};
</script>
<style></style>
