<template>
  <div class="gallery">
    <div class="photos">
      <draggable v-model="filteringPhoto">
        <transition-group name="list" tag="div">
          <div v-for="photo in filteringPhoto" :key="photo.id" class="photo list-item">
            <div class="cross icon_close" @click="onRemovePhoto(photo.id)"></div>
            <div class="edit-icon icon_edit" @click="$emit('showEditPopUp', photo)"></div>
            <div class="mapping-items">Кол-во выделленых областей: {{ Object.values(photo.mapping).length }}</div>
            <div
              :style="'background-image: url(' + photo.img + ');'"
              class="img"
              @click="$emit('openKonva', photo)"
            ></div>
            <div class="description" @click="$emit('showEditPopUp', photo)">
              {{ photo.description ? photo.description : "Нажмите чтоб изменить название" }}
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {mapGetters, mapMutations, mapState} from "vuex";
import axios from "../plugins/axios";
import {filter} from "lodash";
import VueSwal from "vue-swal";
import draggable from "vuedraggable";
Vue.use(VueSwal);

export default {
  components: {
    draggable,
  },
  computed: {
    ...mapState("gallery", ["filters"]),
    ...mapGetters("gallery", ["photos"]),
    photos: {
      get() {
        return this.$store.state.gallery.photos;
      },
      set(val) {
        this.$store.state.gallery.photos = val;
        this.$store.dispatch("gallery/setGallerySort");
      },
    },
    filteringPhoto: {
      get() {
        if (!this.filters.type) {
          return this.photos;
        }
        const photos = Object.values(this.photos);
        return photos.filter((photo) => {
          const polygons = Object.values(photo.mapping);
          // если хотябы 1 мапинг совпал то true
          return polygons.some(
            (poly) =>
              this.filters.type === poly.type &&
              (!this.filters.house || Number(poly.houseId) === this.filters.house) &&
              (!this.filters.section || Number(poly.sectionId) === this.filters.section) &&
              (!this.filters.floor || Number(poly.floorId) === this.filters.floor),
          );
        });
      },
      set(value) {
        this.photos = value;
      },
    },
  },
  // watch: {
  //   filters() {
  //     console.log("filters mutate");
  //   },
  // },
  methods: {
    ...mapMutations("gallery", ["removePhoto"]),
    onRemovePhoto(id) {
      this.$swal({
        title: "Удаление картинки",
        text: "Вы действительно хотите удалить? Весь мапинг тоже будет удален.",
        icon: "warning",
        buttons: ["Отмена", "Да"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.removePhoto({id: id});
          axios.post(Routing.generate("navigator_mapping_remove_photo"), id);
        }
      });
      return false;
    },
    onEditPhoto(id) {
      // axios.post(Routing.generate('navigator_mapping_edit_photo'), id)
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.photo {
  cursor: pointer;
  float: left;
  border: 1px solid #d7d7d7;
  margin: 20px;
  position: relative;
  .img {
    width: 300px;
    height: 300px;
    background-size: cover;
  }
  .cross {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    background: black;
    padding: 2px;
    cursor: pointer;
    line-height: 24px;
    font-size: 24px;
  }
  .edit-icon {
    position: absolute;
    right: 0;
    top: 28px;
    color: white;
    background: black;
    padding: 2px;
    cursor: pointer;
    line-height: 24px;
    font-size: 24px;
  }
  .mapping-items {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    padding: 2px 5px;
    background: black;
    line-height: 24px;
    font-family: OpenSansBold;
  }
  .description {
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    padding: 2px 5px;
    background: black;
    line-height: 24px;
    cursor: pointer;
    font-family: OpenSansBold;
  }
}
</style>
