<template>
  <div class="wrap-info">
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    const qwe = 123;

    return {};
  },
};
</script>

<style lang="scss" scoped>
.wrap-info {
  height: 63px;
  width: 100%;
  font-size: 16px;
}
</style>
