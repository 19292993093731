var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mappigKonva", attrs: { tabindex: "0" } },
    [
      _c(
        "v-stage",
        {
          ref: "stage",
          staticClass: "mapping-stage",
          attrs: {
            config: {
              width: 1920,
              height: 620
            }
          },
          on: {
            click: _vm.clickStage,
            mousemove: _vm.moveImage,
            wheel: _vm.scaleAll,
            mouseup: _vm.stopDragImage
          }
        },
        [
          _c(
            "v-layer",
            { ref: "layerImg" },
            [
              _c("v-image", {
                attrs: {
                  config: {
                    x: 0,
                    y: 0,
                    image: _vm.loadImg,
                    width: _vm.photo.width,
                    height: _vm.photo.height
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layer",
            { ref: "layer" },
            _vm._l(_vm.photo.mapping, function(poly, i) {
              return _c(
                "v-group",
                {
                  key: i,
                  ref: "groupPoly",
                  refInFor: true,
                  attrs: { config: poly },
                  on: {
                    dragend: _vm.dragendGroup,
                    mouseenter: function($event) {
                      return _vm.$emit("mouseOverPoly", poly.coordId)
                    }
                  }
                },
                [
                  _c("v-line", {
                    ref: "line",
                    refInFor: true,
                    attrs: {
                      config: {
                        points: poly.coords,
                        fill: poly.fill,
                        stroke: poly.stroke,
                        strokeWidth: poly.strokeWidth,
                        closed: true,
                        coordId: poly.coordId,
                        bezier: poly.bezier
                      }
                    },
                    on: { contextmenu: _vm.contextmenu }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.coordsToPoints(poly.coords), function(point, k) {
                    return _c("v-circle", {
                      key: k,
                      attrs: {
                        config: {
                          x: point.x,
                          y: point.y,
                          radius: poly.radius
                            ? poly.radius / _vm.deltaAllScale
                            : 2,
                          fill: "transparent",
                          stroke: "red",
                          strokeWidth: 1,
                          draggable: _vm.draggableCircle,
                          coordId: poly.coordId
                        }
                      },
                      on: {
                        contextmenu: _vm.removePoint,
                        mouseover: _vm.mouseoverCircle,
                        mouseout: _vm.mouseoutCircle,
                        dragmove: _vm.dragmoveCircle
                      }
                    })
                  })
                ],
                2
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("context-menu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showContextmenu,
            expression: "showContextmenu"
          }
        ],
        ref: "contextMenu",
        on: {
          showDeletePopUp: _vm.showDeletePopUp,
          edit: _vm.editPoly,
          clone: _vm.copyPoly,
          past: _vm.pastPoly,
          click: function($event) {
            _vm.showContextmenu = false
          },
          submitPolygon: function($event) {
            return _vm.$emit("submitPolygon")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }