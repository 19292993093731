<template>
  <div class="wrap-filters">
    <div class="col col-2">
      <div>
        <label for="type">Тип мапинга</label>
        <select id="type" v-model="filters.type" name="descMapping[]" @change="changeFilters">
          <option v-for="(type, i) in types" :key="i" :value="type.type">{{ type.name }}</option>
        </select>
      </div>
    </div>
    <!--    <div v-if="buildingData.complex && activeFilters('jk')" class="col col-2">-->
    <!--      <div>-->
    <!--        <label for="jk">жилой комплекс</label>-->
    <!--        <select id="jk" v-model="filters.selectedJk" name="descMapping[]">-->
    <!--          <option v-for="complex in buildingData.arrayComplex" :value="complex.id">{{ complex.val }}</option>-->
    <!--        </select>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="col col-2">
      <div v-if="isActiveFilter('house')">
        <label for="house">Дом</label>
        <select id="house" v-model="filters.house" name="descMapping[]" @change="changeFilters">
          <option v-for="house in houses" :value="house.id">{{ house.title }}</option>
        </select>
      </div>
    </div>
    <div class="col col-2">
      <div v-if="isActiveFilter('section')">
        <label for="section">Подъезд</label>
        <select id="section" v-model="filters.section" name="descMapping[]" @change="changeFilters">
          <option v-for="section in sections" :value="section.id">
            {{ section.val }}
          </option>
        </select>
      </div>
    </div>
    <div class="col col-2">
      <div v-if="isActiveFilter('floor')">
        <label for="floor">Этаж</label>
        <select id="floor" v-model="filters.floor" name="descMapping[]" @change="changeFilters">
          <option></option>
          <option v-for="floor in floors" :value="floor.id">{{ floor.val }}</option>
        </select>
      </div>
    </div>
    <div v-if="isActiveFilter('realty')" class="col col-2">
      <div class="multiselect">
        <multiselect
          v-model="filters.realty"
          :options="realtys"
          :multiple="true"
          label="val"
          track-by="val"
          :close-on-select="false"
          :clear-on-select="false"
          :preselect-first="true"
          @change="changeFilters"
        >
          <template slot="selection" slot-scope="{values, search, isOpen}">
            <span v-if="values.length && !isOpen" class="multiselect__single">
              {{ values.length }} выделенно объектов
            </span>
          </template>
        </multiselect>
        <button class="clear-button" @click="filters.realty = ''">х</button>
      </div>
    </div>
    <a
      v-if="openKonva && isActiveFilter('realty')"
      class="action action-relaty icon_success2 house-reset-x create-mapping-x"
      href="#"
      title="применить"
      @click.stop.prevent="$emit('submitPolygon')"
    >
    </a>
  </div>
</template>
<script>
import {mapGetters, mapState, mapMutations} from "vuex";
import axios from "../plugins/axios.js";
import multiselect from "vue-multiselect";

export default {
  components: {
    multiselect,
  },
  props: {
    openKonva: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filtersShow: {
        gallery: {
          jk: ["house", "floor", "section", "realty"],
          house: ["house", "floor", "section", "realty"],
          section: ["section", "realty"],
          floor: ["realty"],
        },
        konva: {
          jk: ["house", "floor", "section", "realty"],
          house: ["house", "floor", "section", "realty", "cottage"],
          section: ["section", "floor", "realty", "cottage"],
          floor: ["realty", "floor", "cottage"],
          realty: ["realty", "cottage"],
          cottage: ["realty", "cottage"],
        },
      },
    };
  },
  computed: {
    ...mapState("gallery", ["buildingData", "filters", "types"]),
    filters: {
      get() {
        return this.$store.getters["gallery/getFilters"];
      },
      set(filters) {
        this.setFilters(filters);
      },
    },
    houses() {
      return Object.values(this.buildingData.house);
    },
    sections() {
      const house = this.houses.find((house) => this.filters.house === house.id);
      if (house) {
        const sections = Object.values(house.section);
        return sections.sort((a, b) => a.val - b.val);
      }

      return [];
    },
    floors() {
      const section = this.sections.find((section) => this.filters.section === section.id);
      return section ? Object.values(section.floor) : [];
    },
    realtys() {
      if (this.filters.floor) {
        //если выбрать этаж и попробовать поменять подьезд
        const floors = this.floors.find((floor) => this.filters.floor === floor.id);
        return floors ? Object.values(floors.realty) : [];
      } else if (this.filters.section) {
        // обеденяем квартры в одомерный массив и перекладываем по стоякам
        const flatten = [];
        const section = this.sections.find((section) => this.filters.section === section.id);
        if (!section) {
          return [];
        }
        const arrays = Object.values(section.floor);
        let maxRealtyCountInFloor = 1;
        for (let iter = 0; iter <= maxRealtyCountInFloor; iter++) {
          arrays.forEach((el) => {
            const realtyArr = Object.values(el.realty);
            if (maxRealtyCountInFloor < realtyArr.length) {
              maxRealtyCountInFloor = realtyArr.length;
            }
            if (realtyArr[iter] === undefined) {
              return;
            }
            flatten.push(realtyArr[iter]);
            realtyArr.shift();
          });
        }
        //console.log(flatten);
        return flatten;
      }
      return [];
    },
  },
  methods: {
    ...mapMutations("gallery", ["resetFloorSectionForHouse", "refreshFilter", "setFilters"]),

    isActiveFilter(select) {
      const pageFilters = this.filtersShow[this.openKonva ? "konva" : "gallery"][select];
      if (this.filters.type && Array.isArray(pageFilters)) {
        return pageFilters.includes(this.filters.type);
      }
      return false;
    },
    changeFilters() {
      const houses = Object.values(this.buildingData.house);
      if (houses.length === 1) {
        this.filters.house = houses[0].id;
      }
      this.setFilters({filters: this.filters});
    },
  },
  watch: {
    resetFilter() {
      this.filters = {};
      this.setFilters({filters: {}});
      this.$showNoty({msg: "Фильтры очищены", type: "info"});
    },
    buildingData(e) {},
  },

  created() {
    this.$store.dispatch("gallery/getBuildingData");
    this.$store.dispatch("gallery/getTypesBuilding");
  },
};
</script>

<style lang="scss" scoped>
@import "vue-multiselect/dist/vue-multiselect.min.css";
</style>
<style lang="scss">
.action-relaty {
  display: block;
  float: left;
  margin-top: 16px;
  margin-left: 5px;
  border: 1px solid #0ca2c0;
  padding: 6px;
  color: #0ca2c0;
}
.multiselect {
  min-height: 30px;
  margin-top: 16px;
}
.multiselect__select {
  height: 30px;
}
.multiselect__tags {
  height: 30px;
  min-height: 30px;
  padding: 3px 40px 0 5px;
  border-radius: 0;
}
.multiselect__input {
  border: none !important;
  outline: none !important;
  padding-bottom: 0;
  padding-top: 2px;
  margin-bottom: 0;
  margin-top: -5px;
  height: 28px !important;
  min-height: 28px !important;
  &:hover {
    border: none !important;
    outline: none !important;
  }
  &:focus {
    border: none !important;
    outline: none !important;
  }
}
.multiselect__content-wrapper {
  margin-top: 0px;
}
.multiselect__placeholder {
  padding-top: 4px;
}
.multiselect {
  position: relative;
  .clear-button {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
