import Vuex from "vuex";
import mapping from "./mapping";
import gallery from "./gallery";
import crmNotify from "./crmNotify";
import VueResource from "vue-resource";
import {filter, find} from "lodash";
import vue from "vue";

vue.use(VueResource);
vue.use(Vuex);
// для сохранения state в localstorage
// import createPersistedState from 'vuex-persistedstate'

import {mapState, mapActions, mapGetters, mapMutations} from "vuex";

const store = new Vuex.Store({
  modules: {
    mapping,
    crmNotify,
    gallery,
  },

  state: {},
  mutations: {
    set(state, {type, items, module}) {
      if (module) {
        state[module][type] = items;
      } else {
        state[type] = items;
      }
    },
  },
  getters: {
    findBy: (state) => (entity, query = {}) => {
      return filter(state[entity], query);
    },
    findByOne: (state) => (entity, query = {}) => {
      return find(state[entity], query);
    },
  },
  actions: {},
  //plugins: [createPersistedState()],
});

window.mapState = mapState;
window.mapActions = mapActions;
window.mapGetters = mapGetters;
window.mapMutations = mapMutations;
window.VueStore = store;

export default store;
