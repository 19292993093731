<template>
  <div ref="contextMenu" class="context-menu">
    <ul>
      <li ref="clone" @click="$emit('submitPolygon')">Сохранить</li>
      <li ref="remove" @click="$emit('showDeletePopUp', $event)">Удалить</li>
      <li ref="edit" @click="$emit('edit', $event)">Редактировать</li>
      <li ref="clone" @click="$emit('clone', $event)">Клонировать</li>

      <li ref="clone" :class="{opacity: disabledPast}" @click="$emit('past', $event)">Вставить</li>

      <!--      <li @click="$emit('move',$event)" ref="move">Преместить</li>-->
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    disabledPast() {
      return !this.$store.state.mapping.copiedElement;
    },
  },
};
</script>

<style lang="scss" scoped>
.context-menu {
  width: 150px;
  //height: 120px;
  position: fixed;
  left: calc(50% - 75px);
  top: 50%;
  border: 1px solid #242424;
  .opacity {
    color: rgba(0, 0, 0, 0.5);
  }
  li {
    width: 150px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    border-top: 1px solid #242424;
    text-indent: 5px;
    cursor: pointer;
    &.opacity {
      color: rgba(0, 0, 0, 0.5);
      cursor: initial;
    }
  }
  li:first-child {
    border-top: 0;
  }
  li:hover {
    background: #dbf1f6;
  }
}
</style>
