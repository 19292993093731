<template>
  <div id="editorJson">
    <ace
      v-model="content"
      lang="json"
      theme="monokai"
      width="100%"
      height="780"
      @onChange="changeAce"
      @init="init"
    ></ace>
    <input type="submit" value="Сохранить" @click="saveJson" />
    <input type="submit" value="Deploy DEV" @click.prevent="deployDev" />
    <input type="submit" value="Deploy PROD" @click.prevent="deployProd" />
  </div>
</template>
<script>
import ace from "vue2-ace-editor";
import {mapGetters, mapState} from "vuex";
import "brace/ext/language_tools";
import "brace/mode/json";
import "brace/theme/monokai";
import "brace/snippets/javascript";
import "brace/ext/searchbox";
// How to enable undo and redo in ace editor
// https://gist.github.com/dhruvpsaru/abe9f98335a92d623e043bb8ff693fa0

export default {
  components: {
    ace,
  },
  data() {
    return {
      content: "",
      editor: {},
    };
  },
  computed: {
    ...mapState(["optionsSiteId"]),
  },
  mounted() {
    setTimeout(() => {
      this.content = JSON.stringify(this.getMappingJsonData(), null, "\t");
      this.editor.session.$worker.addEventListener("annotate", this.errorHandler, false);
    }, 500);
  },
  destroyed() {
    this.editor.session.$worker.removeEventListener("json");
  },
  methods: {
    ...mapGetters(["getMappingJsonData"]),
    changeAce(e) {
      console.log(e);
    },
    errorHandler({data}) {
      this.cursorPosition = this.editor.selection.getCursor();
      const [validationInfo] = data;
      if (validationInfo && validationInfo.type === "error") {
        const v = validationInfo;
        this.$showNoty({
          msg: `В строке ${v.row} в символе ${v.column} ошибка: ${v.text}`,
          type: "error",
          timeout: 3000,
        });
      }
    },
    init(editor) {
      this.editor = editor;
      this.editor.renderer.setScrollMargin(6, 0);
      this.editor.setOptions({
        printMargin: false,
        wrap: true,
        scrollPastEnd: 0.5,
        fontSize: "16px",
      });
    },
    saveJson(e) {
      const changedDataJson = {
        landing: {
          id: this.optionsSiteId,
          data: this.content,
        },
      };
      if (!this.editor.getSession().getAnnotations().length) {
        $.post(Routing.generate("navigator_landing_setting_save"), changedDataJson)
          .done((rs) => {
            this.$showNoty({msg: "Сохранено", type: "success"});
          })
          .fail((rs) => {
            this.$showNoty({msg: "ошибка сохранения", type: "error"});
          });
      } else {
        this.errorHandler({data: this.editor.getSession().getAnnotations()});
      }
      e.preventDefault();
    },
    deployDev() {
      this.$swal({
        title: "Развернуть DEV",
        text: "Вы уверены?",
        icon: "warning",
        buttons: ["Отмена", "Да"],
        dangerMode: true,
      }).then((e) => {
        if (e) {
          const dataJson = {
            id: this.optionsSiteId,
            type: "dev",
          };
          $.get(Routing.generate("navigator_deploy_dev"), dataJson)
            .done((rs) => {
              this.$showNoty({msg: rs, type: "success"});
            })
            .fail((rs) => {
              this.$showNoty({msg: "Deploy error", type: "error"});
            });
        }
      });
    },
    deployProd() {
      this.$swal({
        title: "Развернуть PROD",
        text: "Вы уверены?",
        icon: "warning",
        buttons: ["Отмена", "Да"],
        dangerMode: true,
      }).then((e) => {
        if (e) {
          const dataJson = {
            id: this.optionsSiteId,
            type: "prod",
          };
          $.get(Routing.generate("navigator_deploy_dev"), dataJson)
            .done((rs) => {
              this.$showNoty({msg: rs, type: "success"});
            })
            .fail((rs) => {
              this.$showNoty({msg: "Deploy error", type: "error"});
            });
        }
      });
    },
  },
};
</script>
<style></style>
