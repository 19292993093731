import vue from "vue";
import App from "./App.vue";
import store from "./store/index.js";
import noty from "../plugins/noty";
vue.use(noty);
window.vueJsonEditor = () => {
  console.log("editore");
  vue.config.devtools = false;
  vue.config.productionTip = false;
  new vue({
    store,
    render: (h) => h(App),
  }).$mount("#editorJson");
};
