<template>
  <div
    class="col col-2 custom-attach-field custom-file-loader custom-file-loader-x first"
    :class="{gallery: !openKonva}"
  >
    <div class="housephotos-actions disabled">
      <a
        v-if="openKonva"
        class="action bezier"
        :class="{active: bezierModeOn}"
        href="#"
        title="режим bezier"
        @click.stop.prevent="bezierMode"
        ><div></div
      ></a>
      <a class="action icon_download" href="#" title="загрузить фото" @click.stop.prevent="$emit('downloadPhoto')"> </a>
      <a
        v-if="openKonva"
        class="action icon_success2 house-reset-x create-mapping-x"
        href="#"
        title="применить"
        @click.stop.prevent="$emit('submitPolygon')"
      >
      </a>
      <a class="action icon_refresh" href="#" title="сбросить фильтры" @click.stop.prevent="$emit('resetFilter')"> </a>
      <a
        v-if="openKonva"
        class="action icon_picture"
        href="#"
        title="открыть галлерею"
        @click.stop.prevent="$emit('showGallery')"
      >
      </a>
      <a
        v-if="openKonva"
        class="action icon_reset"
        :class="{rotate: invertSettings}"
        href="#"
        title="инвертировать настройки"
        @click.stop.prevent="$emit('invertSettings')"
      >
      </a>
    </div>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
export default {
  props: {
    openKonva: {
      type: Boolean,
      request: false,
      default: false,
    },
    invertSettings: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      bezierModeOn: false,
    };
  },
  methods: {
    ...mapMutations("gallery", ["refreshFilter"]),
    bezierMode() {
      this.bezierModeOn = !this.bezierModeOn;
      this.$showNoty({msg: "Сменен режим кривой", type: "info"});
      this.$store.commit({type: "mapping/setBezierMode", item: this.bezierModeOn});
    },
  },
};
</script>

<style lang="scss" scoped>
.bezier {
  div {
    width: 14px;
    height: 14px;
    background-image: url("../../../assets/img/line.png");

    background-size: cover;
    background-repeat: no-repeat;
  }
}
.bezier.active {
  div {
    background-image: url("../../../assets/img/bezier.png");
  }
}
.custom-file-loader .housephotos-actions a:last-child {
  border-left: 1px solid #0ca2c0;
}
.custom-file-loader-x {
  width: 211px;
}
.custom-file-loader-x.gallery {
  width: 79px;
}
a.rotate {
  background-color: #0ca2c0;
  color: white;
}
</style>
