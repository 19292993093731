import Vuex from "vuex";
import vue from "vue";

vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    mappingJsonData: {},
    optionsSiteId: 0,
  },
  mutations: {
    set(state, {type, items}) {
      state[type] = items;
    },
    setMappingJsonData(state, items) {
      state.mappingJsonData = items;
    },
  },
  getters: {
    getMappingJsonData(state) {
      return state.mappingJsonData;
    },
  },
  actions: {},
});

window.editorJsonStore = store;

export default store;
