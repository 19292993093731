import axios from "../plugins/axios";
import {remove} from "lodash";
export default {
  namespaced: true,
  state: {
    photos: [],
    buildingData: [],

    types: "",
    filters: [],
  },
  getters: {
    getFilters(state) {
      return state.filters;
    },
  },
  mutations: {
    setPhotos(state, items) {
      state.photos = items;
    },
    removePhoto(state, {id}) {
      state.photos.splice(
        state.photos.findIndex((p) => p.id == id),
        1,
      );
    },
    setData(state, {type, items}) {
      state[type] = items;
    },
    addPhoto(state, {item}) {
      state.photos.unshift(item);
    },
    editPhoto(state, {id, photo}) {
      state.photos.forEach((el, key) => {
        if (el.id === id) {
          state.photos[key].description = photo.description;
          state.photos[key].height = photo.height;
          state.photos[key].width = photo.width;
          state.photos[key].img = photo.img;
        }
      });
    },
    resetFilters(state) {
      state.filters = [];
    },
    resetRealtyFilters(state) {
      state.filters.selectedRealty = [];
    },
    setFilters(state, {filters}) {
      state.filters = filters;
    },
    removePolygonInPhotos(state, mappingId) {
      state.photos.forEach((el) => {
        remove(el.mapping, {id: mappingId});
      });
    },
  },
  actions: {
    async getBuildingData({commit}) {
      makePreloader();
      const endpoint = Routing.generate("navigator_settings_group_array_realty_by_options_site");
      const rq = await axios.post(endpoint, {optionsSiteId: window.customStore.optionsSite.id});
      commit("setData", {type: "buildingData", items: rq.data});
      destroyPreloader();
    },
    async getAllPhotos({commit}) {
      if (window.customStore) {
        makePreloader();
        const endpoint = Routing.generate("navigator_get_all_photos");
        const rq = await axios.post(endpoint, {optionsSiteId: window.customStore.optionsSite.id});
        commit("setPhotos", rq.data);
        this.state.mapping.loading = true;
        destroyPreloader();
      }
    },
    async setGallerySort({state}) {
      const endpoint = Routing.generate("navigator_gallery_sort");
      await axios.post(endpoint, {photos: state.photos});
    },
    async getTypesBuilding({commit}) {
      const endpoint = Routing.generate("navigator_mapping_get_all_types");
      const rq = await axios.post(endpoint);
      commit("setData", {type: "types", items: rq.data});
    },
  },
};
