<template>
  <div class="wrap">
    <div class="form-wrap mapping-page mapping-padding">
      <div class="content-block main-form-type">
        <actions
          :open-konva="openKonva"
          :invert-settings="invertSetting"
          @downloadPhoto="showEditPopUp"
          @submitPolygon="submitPolygon"
          @showGallery="showGallery"
          @resetFilter="resetFilter = !resetFilter"
          @invertSettings="invertSetting = !invertSetting"
        ></actions>
        <filters-panel
          :open-konva="openKonva"
          :reset-filter="resetFilter"
          @submitPolygon="submitPolygon"
        ></filters-panel>
      </div>
    </div>
    <gallery v-show="!openKonva" @openKonva="showKonva" @showEditPopUp="showEditPopUp"></gallery>
    <pop-up v-if="showPopup" ref="popUp" class="pop-up-vue" @cancelPopUp="showPopup = false">
      <pop-up-gallery :photo-pop-up="photoPoUp" @destroyPopUp="showPopup = false"></pop-up-gallery>
    </pop-up>
    <konva2
      v-if="openKonva"
      ref="konva2"
      :invert-setting="invertSetting"
      @editPolygon="selectFilterForPoly"
      @mouseOverPoly="mouseOverPoly"
      @resetFilters="resetFilters"
      @submitPolygon="submitPolygon"
    ></konva2>
    <info v-show="openKonva"> {{ stat }} </info>
  </div>
</template>
<script>
import konva2 from "./konva2.vue";
import gallery from "./gallery.vue";
import popUp from "./ui/popUp.vue";
import filtersPanel from "./filters.vue";
import actions from "./actions.vue";
import popUpGallery from "./gallery/popUp.vue";
import info from "./ui/info";

import {mapGetters, mapMutations, mapState} from "vuex";

export default {
  components: {
    gallery,
    popUp,
    filtersPanel,
    actions,
    popUpGallery,
    konva2,
    info,
  },
  data() {
    return {
      resetFilter: false,
      showPopup: false,
      submitPopUp: false,
      openKonva: false,
      stat: "",
      invertSetting: true,
      photoPoUp: {},
    };
  },
  computed: {
    ...mapState("gallery", ["filters", "photos", "buildingData"]),
  },
  methods: {
    ...mapMutations("gallery", ["resetFilters", "setFilters"]),
    ...mapMutations("mapping", ["setSelectedPhoto"]),

    showEditPopUp(photo = {}) {
      this.photoPoUp = photo;
      this.showPopup = true;
    },
    mouseOverPoly(coordId) {
      // ..бред собачий
      this.photos.forEach((photo) => {
        const mapping = photo.mapping[coordId];
        if (mapping) {
          switch (mapping.type) {
            case "house": {
              this.stat = `Название дома ${mapping.houseNum}`;
              break;
            }
            case "section": {
              this.stat = `Название дома ${mapping.houseNum}, номер подъезда ${mapping.sectionNum}`;
              break;
            }
            case "floor": {
              this.stat = `Название дома ${mapping.houseNum}, номер подъезда ${mapping.sectionNum}, номер этажа ${mapping.floorNum}`;
              break;
            }
            case "realty": {
              this.stat = `Название дома ${mapping.houseNum}, номер подъезда ${mapping.sectionNum}, номер этажа ${
                mapping.floorNum
              }, номера квартир: ${mapping.realty.map((v) => v.val).join(",")}`;
              break;
            }
          }
        }
      });
    },
    selectFilterForPoly() {
      this.photos.forEach((photo) => {
        if (photo.mapping.edit) {
          const {realty, sectionId: section, floorId: floor, houseId: house, type} = photo.mapping.edit;
          this.setFilters({
            filters: {
              realty,
              section: Number(section),
              floor: Number(floor),
              house: Number(house),
              type,
            },
          });
        }
      });
    },
    showGallery() {
      this.openKonva = false;
      this.resetFilters();
    },
    showKonva(photo) {
      this.setSelectedPhoto(photo);

      // this.setFilters({
      //   filters: {
      //     type: photo.type,
      //     jk: photo.jk,
      //     house: photo.house,
      //     section: photo.section,
      //     floor: photo.type === "floor" ? "" : photo.floor,
      //   },
      // });
      this.$store.dispatch("mapping/konvaInit");
      this.openKonva = true;
    },
    submitPolygon() {
      this.photos.forEach((photo) => {
        if (photo.mapping.edit) {
          photo.mapping.edit.type = this.filters.type;
          photo.mapping.edit.houseId = this.filters.house;
          photo.mapping.edit.sectionId = this.filters.section;
          photo.mapping.edit.floorId = this.filters.floor;
          photo.mapping.edit.realty = this.filters.realtys ? this.filters.realtys : [];
        }
      });
      this.$refs.konva2.submitPolygon({
        type: this.filters.type,
        house: this.filters.house,
        section: this.filters.section,
        floor: this.filters.floor,
        realty: this.filters.realtys ? this.filters.realtys : [],
      });
    },
  },
  created() {
    this.$store.dispatch("gallery/getAllPhotos");
  },
};
</script>
<style lang="scss">
.faded-vue {
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}
</style>
<style lang="scss">
* {
  //  background-color: black;
}
</style>
<style lang="scss" scoped></style>
