import Vuex from "vuex";
import vue from "vue";
import axios from "axios";
vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    optionsSiteId: 0,
    domainsList: {},
    fileTypes: {},
    photoList: [],
  },

  mutations: {
    set(state, {type, items}) {
      state[type] = items;
    },
    setPhoto(state, photo) {
      state.photoList.push(photo);
    },
    removePhoto(state, id) {
      state.photoList.splice(
        state.photoList.findIndex((el) => el.id === id),
        1,
      );
    },
  },
  getters: {},
  actions: {
    async loadPhotoList({commit}) {
      const endpoint = Routing.generate(`navigator_settings_get_photo_list`);
      const {data} = await axios.post(endpoint, {settingsId: $("#settings-id").html()});
      commit("set", {type: "photoList", items: data});
    },
    async loadDomainList({commit}) {
      const endpoint = Routing.generate(`navigator_settings_get_domains_list`);
      const {data} = await axios.post(endpoint, {settingsId: $("#settings-id").html()});
      commit("set", {type: "domainsList", items: data});
    },
    async getAllTypesFile({commit}) {
      const endpoint = Routing.generate(`navigator_settings_get_all_types_file`);
      const {data} = await axios.post(endpoint, {landing: $("#settings-id").html()});
      commit("set", {type: "fileTypes", items: data});
    },
  },
});

window.mediaStore = store;

export default store;
