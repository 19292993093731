export const coordsToPoints = (coords) => {
  const points = [];
  coords.forEach((el, i) => {
    if (i % 2 === 0) {
      points[i / 2] = {};
      points[i / 2].x = el;
    } else {
      points[(i - 1) / 2].y = el;
    }
  });
  return points;
};
export const pointsToCoords = (points) => {
  const coords = [];
  points.forEach((el) => {
    coords.push(el.x);
    coords.push(el.y);
  });
  return coords;
};

export const coordsToBezier = (coords) => {
  coords.forEach((p, i) => {
    if (i === 2) {
      coords[i] = "C" + p;
    } else {
      coords[i] = p;
    }
  });
  return "M" + coords.join(",") + "z";
};
export const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const validateSelectedFilters = (filtersData) => {
  let error = "";
  const types = {
    house: "Не выбран дом",
    section: "Не выбран подъезд",
    floor: "Не выбран этаж",
    realty: "Не выбрана квартира",
  };
  if (!filtersData) {
    return "Не выбран тип";
  }
  if (filtersData.type) {
    switch (filtersData.type) {
      case "realty":
        error = !filtersData.realty.length ? types[filtersData.type] : "";
        break;
      default:
        error = !filtersData[filtersData.type] ? types[filtersData.type] : "";
    }
  } else {
    error = "Выберите тип";
  }
  return error;
};
