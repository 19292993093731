import App from "./App.vue";
import store from "./store/index.js";
import vue from "vue";
import noty from "./plugins/noty";
vue.use(noty);
window.vueMapping = () => {
  vue.prototype.$eventBus = new vue();
  vue.config.devtools = true;
  vue.config.productionTip = false;
  new vue({
    render: (h) => h(App),
    store,
  }).$mount("#app");
};
