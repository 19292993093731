<template>
  <div class="pop-up-vue">
    <form ref="form" action="">
      <div>
        <label for="discount_img_path">Загрузка фото для маппинга</label>
        <input id="discount_img_path" ref="file" type="file" name="img_path[]" class="" @change="handleFileUpload" />
        <input
          name="img_name"
          class="loading-file-vue short"
          readonly=""
          type="text"
          :value="file.name"
          @click="uploadPhoto"
        />
        <a class="icon_overview load-img load-file-x" href="#" @click="uploadPhoto"> </a>
      </div>
      <div>
        <label for="descMapping">Название фото</label>
        <input id="descMapping" v-model="name" type="text" name="descMapping[]" class="" />
      </div>
    </form>
  </div>
</template>
<script>
import axios from "../../plugins/axios.js";
import {mapMutations} from "vuex";

export default {
  props: ["photoPopUp", "showPopup"],
  data() {
    return {
      file: {
        name: "Загрузите файл",
      },
      fadedVue: "",
      name: "",
    };
  },
  methods: {
    ...mapMutations("gallery", ["addPhoto", "editPhoto"]),
    uploadPhoto() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
  destroyed() {
    this.$eventBus.$off("submitPopUpGallery");
  },
  created() {
    if (this.photoPopUp.id) {
      this.name = this.photoPopUp.description;
    }
    this.$eventBus.$on("submitPopUpGallery", (data) => {
      const formData = new FormData();
      if (this.photoPopUp.id) {
        formData.append("id", this.photoPopUp.id);
      }

      formData.append("file", this.file);
      formData.append("optionsSiteId", window.customStore.optionsSite.id);
      formData.append("name", this.name);
      axios
        .post(Routing.generate("navigator_mapping_upload_photo"), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((rs) => {
          if (!this.photoPopUp.id) {
            this.addPhoto({item: rs.data.photo});
          } else {
            this.editPhoto({id: rs.data.photo.id, photo: rs.data.photo});
          }
          this.file = "";
          this.$emit("destroyPopUp");
        })
        .catch(function (e) {
          //console.log("FAILURE!!", e);
        });
    });
  },
};
</script>

<style lang="scss" scoped>
.pop-up-vue {
  input[type="text"],
  input[type="file"],
  select {
    width: 100%;
    background-color: white;
  }
}

.load-img {
  margin-left: -32px;
  border: 1px solid #0ca2c0;
}

.loading-file-vue {
  width: 100% !important;
  float: left;
}
</style>
